











































import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { FindPerfilUseCase, DeletePerfilUseCase } from '@/usecases'
import { Page, Pageable, Perfil } from '@/models'
import axios, { CancelToken, CancelTokenSource } from 'axios'
import { DataOptions } from 'vuetify'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import DialogoDeCopiaDePerfis from './DialogoDeCopiaDePerfis.vue'

@Component({
	components: {
		DataTableDeCrud,
		Confirmacao,
		DialogoDeCopiaDePerfis,
	},
})
export default class TelaDePerfis extends Vue {
	@Ref() confirmacao!: Confirmacao
	@Ref() dialogoDeCopiaDePerfis!: DialogoDeCopiaDePerfis
	headers = [{ text: 'Nome', value: 'nome' }]

	totalRegistros = -1
	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}
	carregando = false

	busca = ''

	findUseCase = new FindPerfilUseCase()
	deleteUseCase = new DeletePerfilUseCase()
	cancelToken: CancelTokenSource | null = null
	
	perfis: Perfil[] = []
	indiceDoPerfilSelecionado: string | null = null

	mostra = false;

	created() {
		this.carregar()
	}

	get filtrosInicias() {
		return { busca: '' }
	}

	get podeRemoverPerfil() {
		return UserLoginStore.temAutorizacaoPorOperacaoERecurso('deletar','perfis')
	}

	abriDialogoDeCopiaDePerfis() {
		this.dialogoDeCopiaDePerfis.mostrar() 
	}

	async carregar() {
		try {
			this.carregando = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
			const paginaDePerfis = await this.findUseCase.findAll({
				busca: this.busca || undefined,
				page: this.paginacao.page - 1,
				size: this.paginacao.itemsPerPage,
				axiosConfig: {
					cancelToken: this.cancelToken.token,
				},
			})
			this.perfis = paginaDePerfis.content
			this.totalRegistros = paginaDePerfis.totalElements
		} catch (error) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async buscarPagina(paginavel: Pageable, parametros: {
		busca?: string
	}, cancelToken: CancelToken): Promise<Page<Perfil>> {
		const pagina = await this.findUseCase.findAll({
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.perfis = pagina.content
		return pagina
	}

	get itensFormatados(): DisplayPerfil[] {
		return this.itens.map(item => ({
			...item,
		}))
	}

	get itens() {
		return this.perfis
	}

	async exibeConfirmacao(indice: string){
		this.indiceDoPerfilSelecionado = indice
		this.confirmacao.mostrar()
	}

	async removerPerfil() {
		if(this.indiceDoPerfilSelecionado == null) return
		const perfil = this.itens[this.indiceDoPerfilSelecionado]
		if (!perfil.id) return
		try {
			if(!this.podeRemoverPerfil) 
				throw new Error('Necessário permissão para remover perfis')
			
			await this.deleteUseCase.delete(perfil.id)
			this.perfis = this.perfis.filter(({ id }) => id !== perfil.id)
		} catch (error) {
			AlertModule.setError(error)
		}
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.carregar

	@Watch('busca', { immediate: true })
	onChangeBusca() {
		this.paginacao = {
			...this.paginacao,
			page: 0,
		}
		this.carregar()
	}
}

export interface DisplayPerfil extends Perfil {}
